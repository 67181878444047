<template>
  <div class="main noScroll">
    <div class="topbar">
      <Tabs v-model="currentTabIndex"
            class="tabs"
            key="parent">
        <TabPane v-for="item in tabArr"
                 :key="item.name"
                 :label="item.name"></TabPane>
      </Tabs>
      <div class="topbar-search">
        <div class="flex a-center j-between">
          <div class="flex a-center">
            <template v-if="currentTabIndex===0">
              <Select v-model="departmentType"
                      placeholder="机构类型"
                      class="m-r-10"
                      style="width:160px">
                <Option v-for="item in departmentTypeArr"
                        :value="item.id"
                        :key="item.id">{{ item.name }}</Option>
              </Select>
              <MyTreeSelect :value="departmentName"
                            placeholder="所属机构"
                            clearable
                            :data="departmentTreeArr"
                            class="m-r-10"
                            style="width:160px"
                            @onSelectChange="onChangeDepartment">
              </MyTreeSelect>
            </template>
            <Select v-model="carType"
                    placeholder="车辆类型"
                    class="m-r-10"
                    style="width:160px">
              <Option v-for="item in carTypeArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <DatePicker type="month"
                        placeholder=""
                        @on-change="onChangeDate"
                        class="m-r-10"
                        style="width:160px"></DatePicker>
            <template v-if="currentTabIndex===1">
              <MyTreeSelect :value="carNumber"
                            placeholder="车牌号"
                            :data="carTreeArr"
                            search
                            checked
                            clearable
                            class="m-r-10"
                            style="width:160px"
                            @onCheckChange="onChangeCarNumber">
              </MyTreeSelect>
            </template>
          </div>
          <div class="flex">
            <Button type="primary"
                    class="m-l-10">
              <Icon custom="i-icon icon-sousuo"
                    size="16"></Icon>
              查询
            </Button>
            <Button class="m-l-10">
              <Icon custom="i-icon icon-shuaxin"
                    size="16"></Icon>
              重置
            </Button>
            <Button class="m-r-10">
              <Icon custom="i-icon icon-shangchuan"
                    size="16"></Icon>
              导出
            </Button>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <template>
        <div class="section">
          <div class="section-title">
            <span>运行情况</span>
          </div>
          <ul class="section-list">
            <li class="section-item"
                v-for="(item,index) in refuelAmountArr"
                :key="index">
              <span class="fs-14">{{item.title}}</span>
              <span class="fs-48 m-l-8">{{item.value}}</span><span v-if="index == 6">天</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <Tabs v-model="currentTotalEchartsTabIndex"
                class="tabs"
                key="child7">
            <TabPane v-for="item in totalEchartsTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
          <Echarts height="400px"
                   :options="totalOptions"></Echarts>
        </div>
      </template>
      <div class="section">
        <template v-if="currentTabIndex===0">
          <Tabs v-model="currentTotalTabIndex"
                class="tabs"
                key="child6">
            <TabPane v-for="item in totalTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
        </template>
        <template v-if="currentTabIndex===1">
          <Tabs v-model="currentCarTabIndex"
                class="tabs"
                key="child7">
            <TabPane v-for="item in carTabArr"
                     :key="item.name"
                     :label="item.name"></TabPane>
          </Tabs>
        </template>
        <div class="table">
          <!-- :class="currentTabIndex===2?'p-t-15':''" -->
          <Table ref="table"
                 :height="tableHeight"
                 :columns="getColumns"
                 :data="data"
                 border
                 stripe
                 :loading="tableLoading"
                 @on-selection-change="onChangeSelect"
                 @on-sort-change="onChangeSort">
            <template slot-scope="{ row }"
                      slot="operation">
              <Button @click.stop=""
                      type="text">处理</Button>
            </template>
          </Table>
          <Page class="m-t-10 m-l-10"
                :total="totalPage"
                :current="pageNum"
                :page-size="pageSize"
                show-total
                show-sizer
                show-elevator
                @on-change="onPageChange"
                @on-page-size-change="onPageSizeChange" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Echarts from '@/components/common/Echarts';
import MyTreeSelect from '@/components/common/MyTreeSelect.vue'
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Echarts,
    MyTreeSelect
  },
  data () {
    return {
      currentTabIndex: 0,
      tabArr: [{
        name: '机构维修统计'
      },
      {
        name: '车辆维修统计'
      }
      ],
      carType: -1,
      carTypeArr: [{
        id: 0,
        name: '全部'
      },
      {
        id: 1,
        name: '巡查车'
      },
      {
        id: 2,
        name: '洒水车'
      },
      {
        id: 3,
        name: '转运车'
      },
      ],
      departmentType: 1,
      departmentTypeArr: [{
        id: 1,
        name: '全部'
      },
      {
        id: 2,
        name: '项目'
      },
      {
        id: 3,
        name: '标段'
      },
      {
        id: 4,
        name: '部门'
      },
      {
        id: 5,
        name: '班组'
      },
      ],
      departmentName: '',
      departmentId: '',
      date: [],
      refuelAmountArr: [{
        title: '车辆数',
        value: '0'
      },
      {
        title: '累计维修次数',
        value: '0'
      },
      {
        title: '累计维修费用',
        value: '0'
      },
      {
        title: '累计里程',
        value: '0'
      },
      {
        title: '单车平均维修次数',
        value: '0'
      },
      {
        title: '单车平均维修里程间隔',
        value: '0'
      },
      {
        title: '单车平均维修时间间隔',
        value: '0'
      },
      ],
      currentTotalEchartsTabIndex: 0,
      totalEchartsTabArr: [{
        name: '总维修统计图'
      },],
      currentTotalTabIndex: 0,
      totalTabArr: [{
        name: '各车型维修'
      }, {
        name: '各机构维修情况'
      }, {
        name: '汽修'
      }, {
        name: '电工'
      }, {
        name: '钣金'
      }, {
        name: '喷漆'
      }, {
        name: 'jgjgukj'
      },
      ],
      currentCarTabIndex: 0,
      carTabArr: [{
        name: '各机构维修情况'
      }, {
        name: '汽修'
      }, {
        name: '电工'
      }, {
        name: '钣金'
      }, {
        name: '喷漆'
      }, {
        name: 'jgjgukj'
      },
      ],
      totalOptions: {
        xAxis: {
          type: 'category',
          data: ['05/07', '05/08', '05/09', '05/10', '05/11', '05/12', '05/13']
        },
        yAxis: {
          type: 'value',
          splitLine: {
            show: false
          },
          name: '单位：次'
        },
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          type: 'line',
          smooth: true,
          data: [427, 156, 212, 205, 270, 210, 196],
        }],
        color: ['#25bb96'],
      },
      totalPage: 0,
      pageNum: 1,
      pageSize: 20,
      tableHeight: 0,
      tableLoading: false,
      data: [],
      selectArr: [],
      carNumber: '',
      carNumberId: '',
      columnsCarTypeMaintain: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '车型',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '作业大类',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车辆数',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '累计维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车单次维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '行驶里程(km)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修里程间隔(km/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修时间间隔(天/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
      columnsOrganizationMaintain: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '机构名称',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车辆数',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '累计维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车单次维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '行驶里程(km)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修里程间隔(km/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修时间间隔(天/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
      columnsSingleMaintain: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '车型',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修类',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '作业大类',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车辆数',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '累计维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车单次维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '行驶里程(km)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修里程间隔(km/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '单车平均维修时间间隔(天/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
      columnsCarMaintain: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '车牌号',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '所属机构',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车辆类型',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车龄',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '行驶里程(km)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均单次维修金额(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均维修里程间隔(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均维修时间间隔(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
      columnsCarSingleMaintain: [{
        title: '序号',
        width: 45,
        align: 'center',
        render: (h, params) => {
          let index = params.index + 1
          let num = (this.pageNum - 1) * this.pageSize + index
          return h('span', num)
        }
      },
      {
        title: '车牌号',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '所属机构',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车辆类型',
        key: 'manageName',
        tooltip: true,
        align: 'center'
      },
      {
        title: '车龄',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修类',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修次数(次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '维修金额(元)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '行驶里程(km)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均单次维修金额(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均维修里程间隔(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '平均维修时间间隔(元/次)',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      {
        title: '同车型平均',
        key: 'managePhone',
        tooltip: true,
        align: 'center'
      },
      ],
    };
  },
  computed: {
    ...mapGetters({
      'departmentTreeArr': 'getDepartmentTreeList',
      'carTreeArr': 'getCarTreeList',
    }),
    getColumns () {
      let columns = []
      switch (this.currentTabIndex) {
        case 0:
          switch (this.currentTotalTabIndex) {
            case 0:
              columns = this.columnsCarTypeMaintain
              break;
            case 1:
              columns = this.columnsOrganizationMaintain
              break;
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
              columns = this.columnsSingleMaintain
              break;
            default:
              break;
          }
          break;
        case 1:
          switch (this.currentCarTabIndex) {
            case 0:
              columns = this.columnsCarMaintain
              break;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
              columns = this.columnsCarSingleMaintain
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
      return columns
    }
  },
  mounted () {
    this.init()
  },
  destroyed () {
    window.onresize = null
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateCarTreeList'
    ]),
    init () {
      this.tableHeight = window.innerHeight - 350
      window.onresize = () => {
        this.tableHeight = window.innerHeight - 350
      }
      this.updateDepartmentTreeList()
      this.updateCarTreeList()
      this.getList()
    },
    // 修改所属机构
    onChangeDepartment (section) {
      this.departmentId = section.id
      this.departmentName = section.name
    },
    // 修改车牌号
    onChangeCarNumber (section) {
      let idArr = []
      let valueArr = []
      section.forEach(item => {
        if (item.type === 'car') {
          idArr.push(item.id)
          valueArr.push(item.name)
        }
      })
      this.carNumberId = idArr.join()
      this.carNumber = valueArr.join()
    },
    onChangeDate (date) {
      this.date = date
    },
    onChangeSelect (selection) {
      this.selectArr = selection.map(item => item.id)
    },
    onChangeSort (column, key, order) {

    },
    // 页数改变
    onPageChange (event) {
      this.pageNum = event
      this.getList()
    },
    // 每页条数改变
    onPageSizeChange (event) {
      this.pageSize = event
      this.getList()
    },
    getList () {

    }
  },
}
</script>

<style lang='scss' scoped>
@import "@/scss/iviewCssReset/adminTabs.scss";

.main {
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: #f0f2f5;
  overflow: auto;

  .topbar {
    background-color: #fff;

    .topbar-search {
      padding: 16px;

      .topbar-search-item {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        .topbar-search-item-key {
          width: 70px;
          text-align: right;
          color: #a4a4a4;
        }
      }
    }
  }

  .content {
    .section {
      padding: 0px 15px 15px 15px;
      background-color: #fff;
      margin-top: 16px;

      .section-title {
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid #ddd;

        span {
          font-weight: 700;
          font-size: 16px;
          padding-left: 10px;
          border-left: 2px solid #25bb96;
        }
      }

      .section-list {
        height: 104px;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .section-item {
          span {
            font-weight: 700;
          }
        }
      }
    }

    .table {
      margin-top: 16px;
      flex: 1;
    }
  }
}
</style>
